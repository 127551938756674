.loadingContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
