.none {
  display: none;
}

.page-header {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid var(--colorSplit, rgba(33, 41, 58, 0.06));
}

.layout-header-mobile {
  position: absolute;
  top: 0;
  width: 100%;
  height: 44px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  background-color: #38c172;
  z-index: 999;
}

.header-right-mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

/* override */
.custom-header .ant-badge .ant-badge-multiple-words {
  padding: 0 4px;
}
