.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.headers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80px;
  background-color: #fff;
}

.inbox-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 20px;
  height: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5ea;
}

.inbox-header span {
  font-size: 22px;
}

.inbox {
  flex: 1;
  min-height: 500px;
}

.chatbox-header-ribbon {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.chatbox-header {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5ea;
  border-bottom: 0;
}

.info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.title {
  display: flex;
  flex-direction: column;
}

.title .name {
  font-size: 22px;
}

.title .sub {
  font-size: 14px;
}

.search {
  font-size: 20px;
}

.chatbox-ribbon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 5px 20px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5ea;
}

.inbox-list {
  /* max-height: 675px; */
  height: 10000px;
  overflow-y: scroll;
  border-color: #e5e5ea;
  border-style: solid;
  border-width: 1px;
}

.inbox-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* height: 100%; */
  max-height: 80vh;
}

.yet-another-container {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: 100%;
}

.chatbox-messages {
  max-height: 580px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}

.chat-bubble-container {
  word-break: break-word;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chat-bubble-container.user {
  flex-direction: row-reverse;
}

.chat-bubble-container.other {
  flex-direction: row;
}

.chat-bubble {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.chat-bubble.user {
  color: #ffffff;
  background-color: #38c172;
  align-self: flex-end;
}

.chat-bubble.other {
  background-color: #e3fcec;
  align-self: flex-start;
}

.bubble-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sender {
  font-size: 12px;
  font-weight: bold;
}

.bubble-body {
  font-size: 14px;
}

.bubble-footer {
  display: flex;
  justify-content: flex-end;
}

.other .datetime {
  font-size: 10px;
  color: #777;
}

.user .datetime {
  font-size: 10px;
  color: #fff;
}

.input-container {
  width: 100%;
  background-color: #f7f7f7;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.inbox-box {
  flex: 1;
}

.chat-ui-container {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.chat-item {
  height: 80px;
  /* border-block-: 1px solid rgba(5, 5, 5, 0.06); */
  /* border-block-start: 1px solid rgba(5, 5, 5, 0.06); */
  /* border-block: 1px solid rgba(5, 5, 5, 0.06); */
  border-radius: 12px;
  cursor: pointer;
}

.chat-item:hover {
  background-color: #f0f0f0;
}

.chat-bubble.other.emergency {
  background-color: #f1eefc;
}

.chat-bubble.user.emergency {
  background-color: #7966FF;
}
