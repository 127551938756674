.top_container {
  background-color: #fff;
  padding: 0 24px;
}

.row_space_between {
  display: flex;
  justify-content: space-between;
}

.row_flex_v_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.row_flex_bottom {
  column-gap: 12px;
  display: flex;
  flex-direction: column-reverse;
}

.avatar_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 4px;
  column-gap: 8px;

  .avatar {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.tab_title_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-split, rgba(33, 41, 58, 0.06));
}

.rang_picker_button {
  color: #fff;
  border-color: #38c172;
  background-color: #38c172 !important;

  .ant-picker-input > input {
    width: 82px;
    color: #fff;
  }
}

.tab_data_container {
  padding-bottom: 32px;
}

.report_chart_container {
  background-color: #fff;
  border-radius: 10px;
}

.ant-table-thead > tr > th {
  background: #fff !important;
}

.drawer_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.icon_style {
  color: #b4b6bc;
  width: 20px;
  height: 20px;
}

.draw_body_card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--color-split, rgba(33, 41, 58, 0.06));
}

.draw_body_card_recurring_period {
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  /* border-bottom: 1px solid var(--color-split, rgba(33, 41, 58, 0.06)); */
}

.recurring_period_element {
  padding-bottom: 7px;
  border-bottom: 1px solid var(--color-split, rgba(33, 41, 58, 0.06));
}

.dosage_grid {
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-gap: 16px;
}

.dosage_card_medication_name {
  width: 100%;
  grid-template-columns: 1fr 48px;
  display: grid;
  grid-gap: 8px;
}

.dosage_flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.dosage_flex_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.dosage_flex_line {
  width: 100%;
  align-items: center;
  display: flex;
  gap: 8px;
}

.full_page_form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.form_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.form_item_grid_container {
  width: 100%;
  display: grid;
  gap: 48px;
  grid-template-columns: 480px 1fr;
}

.form_item_grid_container_mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
}

.form_footer_container {
  width: 100%;
  border-radius: 10px;
  background: var(--color-fill-alter, rgba(33, 41, 58, 0.02));
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  align-self: stretch;
}

.from_left_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  align-self: stretch;

  .ant-statistic-title {
    font-size: 12px;
  }
}

.right_flex {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.stretch_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vertical_flex {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal_wrapper {
  padding: 32px 32px 24px 32px;
}

.empty_v_center_box {
  box-sizing: border-box;
  height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dual_button_grid {
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
}
