.layout-root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.layout-content {
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}

.layout-content-mobile {
  width: 100%;
  margin-top: 44px;
  padding: 0;
  min-height: calc(100vh - 44px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
