.page-sider {
  width: 100%;
  height: 100%;
  border-radius: 0 15px 15px 0;
  background-color: #38c172;
}

.notification-badge {
  margin-left: calc(100vw - 170px);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* override */
.ant-layout-sider-collapsed .sider-title {
  display: none;
}
