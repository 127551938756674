.btnIcon {
  width: 85px;
  color: #38c172;
  border: 1px solid #38c172;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  span {
    margin-left: 6px;
  }
}

/* .ant-table {
  border-radius: 10px;
} */

.ant-table-tbody > .custom-row-class {
  margin: 16px 0;
  border-radius: 10px;
  padding: 10px 0;
  height: 60px !important;
}

/* 
.custom-row-class td:last-child {
  border-radius: 10px;
} */
