.dash-board-page-footer {
  display: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-top: 1px solid var(--colorSplit, rgba(33, 41, 58, 0.06));
  .dash-board-page-download {
    padding: 24px;
    border-bottom: 1px solid #e8e9ea;
    .dash-board-page-download-body {
      display: flex;
      padding: 24px;
      background-color: #fbfbfb;
      border-radius: 10px;
      .download-body-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        .dash-board-page-download-body-tile {
          /*  font-family: 'SF Pro Text'; */
          font-style: normal;
          font-weight: 600;
          font-size: 20px !important;
          line-height: 28px;
          /* identical to box height, or 140% */
          display: flex;
          align-items: center;
          /* colorTextHeading */
          color: rgba(33, 41, 58, 0.88);
          margin-bottom: 5px;
        }
        .dash-board-page-download-body-text {
          /*  font-family: 'SF Pro Text'; */
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          /* identical to box height, or 157% */
          display: flex;
          align-items: center;
          /* colorTextSecondary */
          color: rgba(33, 41, 58, 0.65);
        }
      }
      .download-body-left {
        display: flex;
        align-items: center;
      }
    }
  }
  .foot {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .foot-img {
      display: flex;
      align-items: center;
      .text {
        font-size: 14px;
        margin-left: 8px;
        font-weight: 600;
      }
    }
  }
}

.download-body-left-btn {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #ffffff;
  /* colorBorder */
  border: 1px solid rgba(33, 41, 58, 0.15);
  box-shadow: 0px 2px 0px rgba(33, 41, 58, 0.02);
  border-radius: 10px;
  .download-body-left-btn-img {
    margin-right: 10px;
  }
}
