body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Hack, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* common css */

.login_container {
  box-sizing: border-box;
  padding-top: 24px;
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
}

.card_container {
  box-sizing: border-box;
  width: 420px;
  padding: 48px;
}

.row_center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.row_space_between {
  display: flex;
  justify-content: space-between;
}

.footer {
  text-align: center;
  color: rgba(33, 41, 58, 0.65);
}

.ant-select-dropdown .ant-select-item-option-content {
  overflow: visible;
  white-space: unset;
  text-overflow: unset;
}

.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab {
  border-radius: 5px 5px 0 0;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
  background-color: #f5f5f5;
}

.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active {
  background-color: #fff;
  border-bottom: none;
}

.ant-tabs-top >.ant-tabs-nav {
  margin-bottom: 0;
}

.ant-notification .ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  font-size: 14px;
}

.ant-notification .ant-notification-notice {
  padding: 8px 16px;
  width: auto;
}

.ant-notification .ant-notification-notice .ant-notification-notice-icon {
  font-size: 14px;
  padding: 4px;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
  border: none;
}

/* settings page override */
.settings-page-modal.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title +.ant-modal-confirm-content {
  max-width: 100%;
}
.settings-page-modal .ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: #FF5500;
}