.settingsPageTabs {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* no padding in sm */
@media (min-width: 576px) {
  .settingsPageTabs {
    padding: 0 24px 0 24px;
  }
}

.settingsPageTabContent {
  width: 100%;
  background-color: #fff;
  padding: 0 24px 0 24px;
}

.settingsPageItemLeft {
  display: flex;
}

.settingsPageIconWrapper {
  display: inline-block;
  padding: 8px;
}

.settingsPageItemRight {
  display: flex;
  justify-content: flex-end;
}

.inputError {
  border: 1px solid red !important;
}
