.drawer_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.drawer_footer_container {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.icon_style {
  color: #b4b6bc;
  width: 20px;
  height: 20px;
}

.draw_body_card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--color-split, rgba(33, 41, 58, 0.06));
}

.card_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
