/* dashboard */

.dash-board-page {
  width: 100%;
  background-color: #f5f5f5;
  .title {
    /*  font-family: 'SF Pro Text'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: flex-start;
    /* colorPrimaryBase */
    color: #38c172;
    margin-bottom: 8px;
  }
  .dash-board-page-heart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    width: 100%;
    height: 64px;
    background-color: #fff;
  }
  .dash-board-page-home {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #e8e9ea;
    .dash-board-page-home-title {
      height: 22px;
      /* Base/Base Normal */
      /*  font-family: 'SF Pro Text'; */
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(33, 41, 58, 0.88);
      margin-bottom: 16px;
    }
    .dash-board-page-home-text {
      height: 38px;
      /* Heading/Heading 2 */
      /*  font-family: 'SF Pro Text'; */
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      /* identical to box height, or 127% */
      display: flex;
      align-items: center;
      color: rgba(33, 41, 58, 0.88);
    }
  }
  .dash-board-page-summary {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .dash-board-page-content {
    margin: 32px 24px;
    .dash-board-page-recent-patients {
      .dash-board-page-recent-patients-body {
        /* width: 648px; */
        height: 410px;
        overflow: hidden;
      }
    }
    .dash-board-page-appointments {
      .dash-board-page-appointments-body {
        /* width: 648px; */
        height: 410px;
        display: flex;
        align-items: center;
        padding: 10px 24px;
        background-color: #fff;
      }
    }
  }
}

.content-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  /* width: 324px; */
  height: 100px;
  /* colorBgContainer */
  background: #ffffff;
  border-radius: 10px;
  .content-flex-1 {
    flex: 1;
    .text {
      /*  font-family: 'SF Pro Text'; */
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      display: flex;
      align-items: center;
      /* colorText */
      color: rgba(33, 41, 58, 0.88);
    }
    .num {
      /*  font-family: 'SF Pro Text'; */
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */
      display: flex;
      align-items: center;
      /* colorPrimaryBase */
      color: #38c172;
    }
  }
  .new-patient-img {
    width: 48px;
    height: 48px;
    /* Light Blue 2 */
    background: #ebf8fe;
    border-radius: 10px;
  }
}
